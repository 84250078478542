<template>
  <div class="plenary">
    <img class="background" src="../../assets/image/background.png" alt srcset />
    <header>
      <img src="../../assets/image/logo.png" alt srcset />
    </header>
    <van-divider :style="dividerStyle">讲者介绍</van-divider>
    <div class="chairmanBox">
      <img class="chairman_img" :src="chairmanUrl" alt />
    </div>
    <div class="guestBox">
      <div class="guestBoxtext">*按首字母排序，排名不分先后（讲者信息陆续更新中）</div>
      <div class="guest">
        <van-index-bar :sticky="false" :index-list="indexList">
          <div v-for="(items,index) in guestList" :key="index">
            <van-index-anchor class="guest_index" :index="items[0].chart" />
            <van-cell v-for="(item,i) in items" :key="i">
              <div class="guest_data">
                <div class="guest_img">
                  <div class="img_box">
                    <img class="imgs" :src="item.image" alt />
                  </div>
                </div>
                <div class="gues_info">
                  <p class="guestName">{{item.name}}&nbsp;&nbsp;</p>
                  <p class="guestText" v-html="item.detail"></p>
                </div>
              </div>
            </van-cell>
          </div>
        </van-index-bar>
      </div>
    </div>
    <div class="footer-fill"></div>
    <el-footer :index="1" />
  </div>
</template>

<script>
import footer from "../../components/footer/footer-tempate.vue";
import { getGuest, getConfig } from "../../api/index";
export default {
  components: {
    "el-footer": footer
  },
  data() {
    return {
      dividerStyle: {
        color: "#FFFFFF",
        borderColor: "#fff",
        padding: "0 10%",
        "font-size": "18px"
      },
      guestList: [],
      indexList: [],
      chairmanUrl: ""
    };
  },
  methods: {
    getGuest() {
      getGuest()
        .then(res => {
          this.guestList = res.data;
          for (const key in res.data) {
            this.indexList.push(key);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getConfig() {
      getConfig({ name: "chairman", type: "1" })
        .then(res => {
          this.chairmanUrl = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    this.getGuest();
    this.getConfig();
  }
};
</script>

<style lang="less" scoped>
.plenary {
  min-height: 100vh;
  // background-image: url("../../assets/image/background.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  .background {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;
    z-index: -1;
  }
  header {
    padding: 10px 0;
    width: 80%;
    margin: 0 auto;
    img {
      max-width: 100%;
    }
  }
  .box {
    height: 46px;
  }
  .van-hairline--bottom::after {
    border-bottom-width: 0;
  }
  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }
  .van-nav-bar {
    background-color: #f2986e;
  }
  .cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
    width: 92%;
    margin: 0 4% 10px 4%;
    background-color: #693853;
    border: 1px solid #fff;
    .title {
      color: #fff;
      font-size: 16px;
    }
  }
  .chairmanBox {
    width: 90%;
    .chairman_img {
      width: 100%;
    }
    // height: 220px;
    // background-image: url("../../assets/image/大会主席背景_03.png");
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    color: #ffffff;
    margin: 0 auto;
    padding-top: 20px;
    .chairmanTitle {
      width: 50%;
      height: 27px;
      border: #ffffff solid 1px;
      margin: 0 auto;
      text-align: center;
      font-size: 16px;
      line-height: 27px;
      font-weight: 400;
    }
    .chairman {
      padding-top: 10px;
      .chairmanImg {
        background-color: transparent;
        width: 91px;
        height: 91px;
        margin: 0 auto;
        border-radius: 0 0 35px 0;
        border: #ffffff solid 1px;
        .imgs {
          margin: 3px;
          background-color: #693853;
          width: 85px;
          height: 85px;
          border-radius: 0 0 32px 0;
        }
      }
      .chairmanName {
        font-size: 14px;
        height: 28px;
        line-height: 28px;
        width: 91px;
        margin: 0 auto;
      }
      .chairmanText {
        font-size: 10px;
        width: 91px;
        margin: 0 auto;
      }
    }
  }
  .guestBox {
    width: 90%;
    margin: 0 auto;
    background-color: rgb(106, 56, 83, 0.8);
    color: #fff;
    padding-bottom: 10px;
    .guestBoxtext {
      font-size: 10px;
      margin-top: 20px;
      margin-left: 20px;
      padding: 10px 0;
    }
    .guest {
      .guest_index {
        background-color: #995c85;
        height: 20px;
      }
      /deep/.van-index-anchor {
        line-height: 20px;
      }
      /deep/.van-cell {
        background-color: transparent;
      }
      /deep/.van-index-anchor {
        color: #fff;
      }
      /deep/.van-cell__value--alone {
        color: #fff;
      }
      .guest_data {
        display: flex;
        .guest_img {
          flex: none;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          justify-content: center;
          width: 30%;
          min-width: 80px;
          min-height: 80px;
          .img_box {
            background-color: transparent;
            width: 61px;
            height: 61px;
            margin: 0 auto;

            border-radius: 0 0 35px 0;
            border: #ffffff solid 1px;
            .imgs {
              margin: 3px;
              background-color: #693853;
              width: 55px;
              height: 55px;
              border-radius: 0 0 32px 0;
            }
          }
        }
        .gues_info {
          flex: none;
          width: 65%;
          p {
            margin: 0;
          }
          .guestName {
            font-size: 14px;
            // height: 28px;
            // line-height: 28px;
          }
          .guestText {
            font-size: 10px;
            line-height: 14px;
            white-space: pre-wrap;
            // margin: 0 auto;
          }
        }
      }
    }
  }
}
.footer-fill {
  height: 37px;
}
</style>